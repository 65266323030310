import { MouseEventHandler } from "react";
import TransactionButtonInternal from "./transaction-button-internal";

type Props = {
	title: string,
	onClick: MouseEventHandler
}

export default function TransactionButton({ title, onClick }: Props) {
   
	return (
      <button type="submit" onClick={onClick} className="btn-neutral flex items-center justify-center mt-4 border border-white">
         <TransactionButtonInternal title={title} />
      </button>
   );
}