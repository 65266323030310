import { Account } from '@radixdlt/radix-dapp-toolkit';
import { MetaFunction } from '@remix-run/cloudflare';
import { useRevalidator, useFetcher } from '@remix-run/react';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import TokenInput from '~/components/token-input';
import { useRadix } from '~/rdt/radixProvider';
import AccountSelector from '~/components/account-selector';
import TransactionButton from '~/components/transaction-button';
import { NFT_COMPONENT_ADDRESS } from '~/lib/util';



export const meta: MetaFunction = () => {
   return [{ title: 'EBC10 Hackathon' }, { name: 'description', content: 'Claim your participation badge and earn eternal bragging rights.' }];
};

// export async function loader({ context }: Props) {
//    const kv = context.GOVERNANCE_WALLETS;

//    return json({});
// }

export default function ClaimPage() {
   const { sendTransaction } = useRadix();

   const [connected, setConnected] = useState(false);
   const [account, setAccount] = useState<Account | null>(null);
   const [projectName, setProjectName] = useState('');
   const [warningMessage, setWarningMessage] = useState('');

   function onAccount(account: Account) {
		setAccount(account);
	}

   async function onBridge() {
      if (!account) {
         return;
      }

      

      const transactionManifest = `
CALL_METHOD
	Address("${NFT_COMPONENT_ADDRESS}")
	"get_hackathon_badge"
	"${projectName}"
;

CALL_METHOD 
	Address("${account.address}") 
	"deposit_batch" 
	Expression("ENTIRE_WORKTOP")
;`;

      await sendTransaction(transactionManifest, `Claim participation badge for EBC10 Hackathon`);

      
   }

   return (
     <section className="mx-4 mb-8 xl:mx-auto xl:w-1/2">
       <h1 className="py-4 text-left text-3xl font-bold text-white lg:p-8 lg:pb-4 lg:text-4xl">
         EBC10 Hackathon
       </h1>
       <h2 className="pb-4 lg:px-8 text-left text-xl lg:text-2xl font-bold text-white">
         Claim your participation badge and earn eternal bragging rights
       </h2>

       <AccountSelector onAccount={onAccount} />

       <section className="mt-8 flex flex-col rounded-xl lg:rounded-2xl bg-white p-4 lg:p-8">
         <div className="flex items-center justify-between">
           <label htmlFor="tokenInput" className="text-sm text-neutral-800">
             Team Name
           </label>
          
         </div>
         <div className="flex flex-wrap items-center justify-between">
           <TokenInput value={projectName} onChange={setProjectName} />

          </div>
       </section>
       {warningMessage ? (
         <p className="text-md mt-2 rounded-md bg-red-800 px-4 py-1 text-center text-base font-medium text-white shadow-sm">
           {warningMessage}
         </p>
       ) : null}

       <TransactionButton title="Claim Badge" onClick={onBridge} />
     </section>
   );
}
