import { useEffect, useRef, useState } from 'react';
import { useRdtState } from '~/rdt/hooks/useRdtState';
import { useRadix } from '~/rdt/radixProvider';

type Props = {
   title: string;
};

export default function TransactionButtonInrernal({title}: Props) {
   const { transactionState, transaction } = useRadix();
	const walletData = useRdtState();
   const prevTransactionStateRef = useRef<typeof transactionState>();

	const [walletConnected, setWalletConnected] = useState(false);

   useEffect(() => {
     if (walletData?.accounts.length) {
        setWalletConnected(true);
     } else {
        setWalletConnected(false);
     }
   }, [walletData]);

   useEffect(() => {
      //assign the ref's current value to the count Hook
      prevTransactionStateRef.current = transactionState;
   }, [transactionState]);

   return (
     <>
       {!walletConnected ? (
         <>
           <svg width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mt-1 mr-2 h-7 w-7">
             <path
               d="M170.912 306.613C166.762 306.613 162.817 304.63 160.343 301.216L108.688 229.63H75V203.567H115.352C119.542 203.567 123.467 205.57 125.92 208.964L168.132 267.447L232.543 120.309C234.628 115.566 239.309 112.5 244.481 112.5H325V138.563H253.005L182.85 298.804C180.969 303.097 176.942 306.04 172.281 306.531C171.852 306.592 171.382 306.613 170.912 306.613Z"
               fill="currentColor"
             />
           </svg>
           Connect Wallet
         </>
       ) : null}
       {/* Transaction needs to be signed */}
       {transactionState == "submitting" ? (
         <>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 h-6 w-6 animate-spin">
             <path
               strokeLinecap="round"
               strokeLinejoin="round"
               d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
             />
           </svg>
           Sign in the Radix Mobile wallet
         </>
       ) : null}

       {/* Transaction ended with an error */}
       {transactionState === "error" ? (
         <>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 h-6 w-6">
             <path
               strokeLinecap="round"
               strokeLinejoin="round"
               d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
             />
           </svg>
           {title}
         </>
       ) : null}

       {/* Transaction was signed */}
       {transactionState === "idle" && prevTransactionStateRef.current === "submitting" && transaction?.intentHash ? (
         <>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 h-6 w-6">
             <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
           </svg>
           Transaction Completed
         </>
       ) : null}

       {/* Initial state, where transaction can be initiated */}
       {walletConnected && transactionState == "idle" && prevTransactionStateRef.current !== "submitting" ? (
         <>
           <svg className="mr-3 h-6 w-6" viewBox="2 2 16 16" xmlns="http://www.w3.org/2000/svg">
             <path
               d="M10.986 5.456a.608.608 0 0 0-.827 0 .564.564 0 0 0 0 .809l3.233 3.16H5.29a.579.579 0 0 0-.584.572.58.58 0 0 0 .584.572h8.102l-3.233 3.16a.564.564 0 0 0 0 .81c.11.107.257.167.414.167.155 0 .302-.06.413-.168l4.072-3.982a.778.778 0 0 0 0-1.119l-4.072-3.98z"
               fill="currentColor"
               fillRule="nonzero"
             ></path>
           </svg>
           {title}
         </>
       ) : null}
     </>
   );
}
