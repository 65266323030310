type Props = {
	value: string,
	onChange: (value: string) => void
}

export default function TokenInput({value, onChange}: Props) {
	return (
      <input
         type="text"
         value={value}
         onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChange((evt.target as HTMLInputElement).value)}
         placeholder="My cool hackaton project"
         autoComplete="off"
         spellCheck="false"
         className="flex-auto w-1/2 lg:w-60 mr-4 bg-transparent border-none p-0 text-4xl text-black placeholder:text-neutral-400/80"
      />
   );
}